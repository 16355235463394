import "../utils/server/firebase";
import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { getAuth } from "firebase/auth";
import { UserNegociosProvider } from "../context/UserNegociosContext";

const auth = getAuth();

const DashboardNegociosLayout = ({ children }) => {
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/negocios/login");
      }
    });

    return unsub;
  });

  return <UserNegociosProvider>{children}</UserNegociosProvider>;
};

export default DashboardNegociosLayout;
