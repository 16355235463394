import React, { createContext, useEffect, useState } from "react";
import "../utils/server/firebase";
import { getAuth } from "firebase/auth";
import { getCompradorById } from "../utils/server/negocios";

const auth = getAuth();

const UserNegociosContext = createContext();

const UserNegociosProvider = ({ children }) => {
  const [userLogged, setUserLogged] = useState(null);

  const handleUserLogged = (user) => {
    setUserLogged(user);
  };

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const fromNegocios = await getCompradorById(user.uid);
        setUserLogged({ ...fromNegocios, id: user.uid });
      } else {
        setUserLogged(null);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <UserNegociosContext.Provider
      value={{
        userLogged,
        handleUserLogged,
      }}
    >
      {children}
    </UserNegociosContext.Provider>
  );
};

export { UserNegociosContext, UserNegociosProvider };
