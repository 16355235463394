import React, { useState } from "react";
import {
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import DashboardNegociosLayout from "../../layouts/DashboardNegociosLayout";
import Seo from "../../assets/components/seo";
import { Logout, Today } from "@mui/icons-material";
import AddAppointment from "../../assets/components/Negocios/AddAppointment";
import Navbar from "../../assets/components/Navbar";
import Footer from "../../assets/components/Footer";
import { getAuth, signOut } from "firebase/auth";
import { graphql, navigate } from "gatsby";
import NotificationsList from "../../assets/components/Negocios/NotificationsList";
import MyAppointmentList from "../../assets/components/Negocios/MyAppointmentList";
import ListNegocios from "../../assets/components/Negocios/ListNegocios";

const HomeNegocios = () => {
  const auth = getAuth();
  const [search, setSearch] = useState("");
  const [openAddAppointment, setOpenAddAppointment] = useState(false);
  const [openMyAppointments, setOpenMyAppointments] = useState(false);
  const [compradorSelected, setCompradorSelected] = useState();

  return (
    <DashboardNegociosLayout>
      <Container maxWidth="lg">
        <Seo title="Negocios - Iniciar sesión" />
        <Navbar />
        <Stack direction="column" spacing={4} marginTop={12} marginBottom={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                fullWidth
                label="Buscar compradores por nombre de la empresa o giro."
                variant="standard"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Tooltip title="Mis citas" placement="top">
                <IconButton
                  color="primary"
                  onClick={() => setOpenMyAppointments(true)}
                >
                  <Today />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <NotificationsList />
            </Grid>
            <Grid item>
              <Tooltip title="Cerrar sesión" arrow placement="top">
                <IconButton
                  color="error"
                  onClick={() => {
                    signOut(auth);
                    typeof window !== "undefined" &&
                      window.localStorage.removeItem("correo");
                    navigate("/negocios/login");
                  }}
                >
                  <Logout />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <ListNegocios
            search={search}
            handleSelectComprador={setCompradorSelected}
            handleOpenAddAppoinment={setOpenAddAppointment}
          />
        </Stack>
      </Container>
      <Footer />

      {openAddAppointment ? (
        <AddAppointment
          open={openAddAppointment}
          comprador={compradorSelected}
          onClose={() => {
            setCompradorSelected(null);
            setOpenAddAppointment(false);
          }}
        />
      ) : null}

      {openMyAppointments ? (
        <MyAppointmentList
          open={openMyAppointments}
          onClose={() => setOpenMyAppointments(false)}
        />
      ) : null}
    </DashboardNegociosLayout>
  );
};

export default HomeNegocios;

export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["negocios"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
