import { app } from "../../../utils/server/firebase";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  getFirestore,
  or,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { getTimes } from "../../../utils/functions";
import { UserNegociosContext } from "../../../context/UserNegociosContext";

import moment from "moment-timezone";
import "moment/dist/locale/es";

moment().locale("es");
moment.tz.setDefault("America/Mexico_City");

const db = getFirestore(app);
const auth = getAuth(app);

const AddAppointment = ({ open, comprador, onClose }) => {
  const { userLogged } = useContext(UserNegociosContext);
  const enableDates = [
    moment("2023-11-15", "YYYY-MM-DD"),
    moment("2023-11-16", "YYYY-MM-DD"),
    moment("2023-11-17", "YYYY-MM-DD"),
  ];
  const [loading, setLoading] = useState(false);
  const [dateSelected, setDateSelected] = useState();
  const [appointments, setAppointments] = useState([]);
  const [data, setData] = useState({
    start: null,
    end: null,
    status: "pending",
  });

  const onSubmit = async () => {
    try {
      if (!data.start || !data.end) {
        return toast.info("Debes seleccionar horario para agendar la cita.");
      }

      setLoading(true);

      // Guarda la cita en Firebase
      const citasRef = collection(db, "citasNegocios"); // Ajusta el nombre de la colección según tu caso
      const owner = {
        id: auth.currentUser.uid,
        empresa: userLogged.empresa,
        nombreContacto: userLogged.nombreContacto,
        email: userLogged.email,
        telefono: userLogged.telefono,
      };
      const lead = {
        id: comprador.id,
        empresa: comprador.empresa,
        nombreContacto: comprador.nombreContacto,
        email: comprador.email,
        telefono: comprador.telefono,
      };

      await addDoc(citasRef, { ...data, owner, lead });

      // Limpia el estado y cierra el diálogo
      setData({ start: null, end: null, status: "pending" });
      setDateSelected(null);
      toast.success("Se ha enviado su invitación a una cita");

      onClose();
    } catch (error) {
      toast.error("Error al guardar la cita:", error);
      // Maneja el error de la forma que prefieras
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!dateSelected) return;

    const unsub = onSnapshot(
      query(
        collection(db, "citasNegocios"),
        or(
          where("lead.id", "==", comprador.id),
          where("owner.id", "==", comprador.id)
        )
      ),
      (querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        results = results
          .filter((result) => result.status === "accepted")
          .filter((result) => {
            const start = moment(result.start.toDate());
            return dateSelected.isSame(start, "date");
          });

        setAppointments(results);
      }
    );

    return () => unsub();
  }, [dateSelected]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Agendar cita con {comprador.empresa}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <DialogContentText>
            Seleccione la fecha y horarios disponibles para agendar la cita.
            Recuerda que las citas se contemplan con una duración de 30 minutos
            cada una.
          </DialogContentText>
          <TextField
            select
            fullWidth
            label="Seleccione la fecha"
            variant="standard"
            value={dateSelected?.toISOString() || ""}
            onChange={(e) => {
              setData({ start: null, end: null, status: "pending" });
              setDateSelected(moment(e.target.value));
            }}
          >
            {enableDates.map((date, key) => (
              <MenuItem key={key} value={date.toISOString()}>
                {date
                  .toISOString()
                  .replace(/T.*/, "")
                  .split("-")
                  .reverse()
                  .join("-")}
              </MenuItem>
            ))}
          </TextField>
          {dateSelected ? (
            <Grid container spacing={1}>
              {getTimes(dateSelected, 17, 20, appointments).map((time, key) => (
                <Grid key={key} item>
                  <Chip
                    clickable
                    color="primary"
                    // TODO: Arreglar validacion del variant porque pinta todos de filled cuando le das click a cualquiera
                    variant={
                      time.start.isSame(data.start) ? "filled" : "outlined"
                    }
                    label={`${time.start.format("HH:mm")} - ${time.end.format(
                      "HH:mm"
                    )}`}
                    onClick={() => {
                      setData({
                        ...data,
                        start: time.start.toDate(),
                        end: time.end.toDate(),
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={loading} onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="contained" disabled={loading} onClick={onSubmit}>
          Agendar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAppointment;
