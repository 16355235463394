import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Badge,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { Notifications } from "@mui/icons-material";
import { updateStatusAppointment } from "../../../utils/server/negocios";
import { app } from "../../../utils/server/firebase";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { UserNegociosContext } from "../../../context/UserNegociosContext";

const NotificationsList = () => {
  const db = getFirestore(app);
  const { userLogged } = useContext(UserNegociosContext);
  const popRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [saving, setSaving] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);

  const onSubmit = async (item, status) => {
    try {
      setSaving(true);

      const alreadyScheduled = appointments.some((appointment) => {
        const appStart = moment(appointment.start.toDate());
        const appEnd = moment(appointment.end.toDate());

        const itemStart = moment(appointment.start.toDate());
        const itemEnd = moment(appointment.end.toDate());

        return itemStart.isSame(appStart) && itemEnd.isSame(appEnd);
      });

      if (alreadyScheduled) {
        toast.error(
          "Ya se aceptó una cita para esté horario. La cita será marcada como denegada"
        );

        await updateStatusAppointment(item.id, "denied");
      } else {
        await updateStatusAppointment(item.id, status);

        toast.success("Se ha confirmado la cita");
      }

      setSaving(false);
    } catch (error) {
      setSaving(false);
      toast.error("Error al actualizar la cita:", error);
      // Maneja el error de la forma que prefieras
    }
  };

  useEffect(() => {
    if (!userLogged) return;

    const unsub = onSnapshot(
      query(
        collection(db, "citasNegocios"),
        where("lead.id", "==", userLogged.id)
      ),
      (querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        const notis = results.filter(({ status }) => status === "pending");

        const scheduled = results.filter(({ status }) => status === "accepted");

        setTotalNotifications(notis.length);
        setNotifications(notis);
        setAppointments(scheduled);
      }
    );

    return () => unsub();
  }, [userLogged]);

  return (
    <>
      <Tooltip title="Notificaciones" placement="top">
        <IconButton
          onClick={(e) => (!saving ? setAnchorEl(e.currentTarget) : null)}
        >
          <Badge badgeContent={totalNotifications} color="primary">
            <Notifications color="action" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => (!saving ? setAnchorEl(null) : null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        ref={popRef}
      >
        <List disablePadding>
          {totalNotifications === 0 ? (
            <ListItem>
              <Grid container width={300} spacing={1}>
                <Grid item xs={12}>
                  <Typography textAlign="center">Estás al día 👌🏻</Typography>
                </Grid>
              </Grid>
            </ListItem>
          ) : null}

          {totalNotifications > 0 &&
            notifications.map((appointment) => (
              <ListItem key={appointment.id} divider>
                <Grid container maxWidth={400} spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {`${appointment.owner.empresa} te envió una solicitud de
                            cita para el día ${moment(
                              appointment.start.toDate()
                            ).format("D MMM [a las] h:ss a")}`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      disabled={saving}
                      loading={saving}
                      onClick={() => onSubmit(appointment, "accepted")}
                    >
                      Confirmar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      disabled={saving}
                      loading={saving}
                      onClick={() => onSubmit(appointment, "rejected")}
                    >
                      Rechazar
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};

export default NotificationsList;
