import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { app } from "../../../utils/server/firebase";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { UserNegociosContext } from "../../../context/UserNegociosContext";
import { green, orange, red } from "@mui/material/colors";
import moment from "moment";
import "moment/dist/locale/es";

moment().locale("es");

const MyAppointmentList = ({ open, onClose }) => {
  const db = getFirestore(app);
  const { userLogged } = useContext(UserNegociosContext);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    if (!userLogged) return;

    const unsub = onSnapshot(
      collection(db, "citasNegocios"),
      (querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        results = results
          .map((result) => {
            if (result.owner.id === userLogged.id) {
              return { ...result, isOwner: true };
            }

            if (result.lead.id === userLogged.id) {
              return { ...result, isOwner: false };
            }
          })
          .filter((x) => x);

        setAppointments(results);
      }
    );

    return () => unsub();
  }, [userLogged]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Mis citas</DialogTitle>

      {!appointments.length ? (
        <DialogContent>
          <DialogContentText>
            No tienes citas agendadas. Selecciona a una de las empresas y da
            click en el icono superior para agendar
          </DialogContentText>
        </DialogContent>
      ) : null}

      {appointments.length ? (
        <DialogContent>
          <DialogContentText variant="body2" marginBottom={2}>
            Aquí se muestran el listado de citas que ya fueron aceptadas.
            También podrás visualizar las citas pendientes de aceptar o las que
            fueron rechazadas.
          </DialogContentText>
          <List dense disablePadding>
            {appointments
              .sort((a, b) => a.start.toDate() - b.start.toDate())
              .map((appointment, key) => (
                <ListItem key={key} divider disableGutters disablePadding>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          variant="caption"
                          color={
                            appointment.status === "accepted"
                              ? green[500]
                              : appointment.status === "pending"
                              ? orange[700]
                              : red[500]
                          }
                        >
                          {appointment.status === "accepted"
                            ? "Aceptada"
                            : null}
                          {appointment.status === "pending"
                            ? "Pendiente"
                            : null}
                          {appointment.status === "rejected"
                            ? "Rechazada"
                            : null}
                          {appointment.status === "denied" ? "Denegada" : null}
                        </Typography>
                        <Typography variant="body1" fontWeight={500}>
                          {appointment.isOwner
                            ? appointment.lead.empresa
                            : appointment.owner.empresa}
                          {" — "}{" "}
                          <Typography
                            variant="body2"
                            component="span"
                            fontWeight={500}
                          >
                            {appointment.isOwner
                              ? appointment.lead.nombreContacto
                              : appointment.owner.nombreContacto}
                          </Typography>
                        </Typography>
                        <Typography variant="body2">
                          {moment(appointment.start.toDate()).format(
                            "MMMM DD, YYYY (HH:mm a"
                          )}
                          -{" "}
                          {moment(appointment.end.toDate()).format("HH:mm a)")}
                        </Typography>
                      </>
                    }
                    secondary={
                      <>
                        <Typography variant="body2">
                          {appointment.isOwner
                            ? appointment.lead.telefono
                            : appointment.owner.telefono}
                        </Typography>
                        <Typography variant="body2">
                          {appointment.isOwner
                            ? appointment.lead.email
                            : appointment.owner.email}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </DialogContent>
      ) : null}

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyAppointmentList;
