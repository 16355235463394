import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Event } from "@mui/icons-material";
import { UserNegociosContext } from "../../../context/UserNegociosContext";
import { getAllCompradores } from "../../../utils/server/negocios";

const ListNegocios = ({
  search,
  handleSelectComprador,
  handleOpenAddAppoinment,
}) => {
  const { userLogged } = useContext(UserNegociosContext);
  const [loading, setLoading] = useState(true);
  const [compradores, setCompradores] = useState([]);

  useEffect(() => {
    const init = async () => {
      if (!userLogged) return;

      setLoading(true);

      const resp = await getAllCompradores();
      const filtered = resp.filter((comprador) => {
        return comprador.id !== userLogged.id;
      });

      setCompradores(filtered);

      setLoading(false);
    };
    init();
  }, [userLogged]);

  if (loading) return <CircularProgress size={50} color="primary" />;

  return (
    <>
      {compradores.length === 0 ? (
        <Typography>No hay compradores registrados aún</Typography>
      ) : (
        <Grid container spacing={3} width="100%">
          {compradores
            .filter((comprador) => {
              if (!search) return true;
              const empresa = comprador.empresa.toLowerCase();
              const giro = comprador.giro.toLowerCase();
              const value = search.toLowerCase();
              return empresa.includes(value) || giro.includes(value);
            })
            .map((comprador, key) => (
              <Grid key={key} item xs={12} sm={6} md={4}>
                <Card>
                  <CardHeader
                    sx={{
                      paddingBottom: 0,
                    }}
                    title={
                      <>
                        <Typography
                          variant="caption"
                          color="primary"
                          component="p"
                        >
                          {comprador.giro}
                        </Typography>
                        <Typography variant="body1">
                          {comprador.empresa}
                        </Typography>
                      </>
                    }
                    subheader={
                      <>
                        <Typography
                          variant="caption"
                          component="p"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {comprador.telefono}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="p"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {comprador.email}
                        </Typography>
                      </>
                    }
                    action={
                      <Tooltip title="Agendar cita" arrow placement="top">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            handleSelectComprador(comprador);
                            handleOpenAddAppoinment(true);
                          }}
                        >
                          <Event />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                  <CardContent>
                    <Tooltip title={comprador.descripcion}>
                      <Typography variant="body2">
                        {comprador.descripcion.slice(0, 150)}...
                      </Typography>
                    </Tooltip>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
};

export default ListNegocios;
