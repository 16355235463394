import { app } from "./firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

const db = getFirestore(app);

export const getCompradorById = async (id) => {
  const docRef = doc(db, "negocios", id);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const getAllCompradores = async () => {
  let results = [];
  const q = query(collection(db, "negocios"));
  const querySnapshot = await getDocs(q);

  querySnapshot.docs.map((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });

  return results;
};

export const getAllAppointments = async () => {
  let results = [];
  const q = query(collection(db, "citasNegocios"));
  const querySnapshot = await getDocs(q);

  querySnapshot.docs.map((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });

  return results;
};

export const getLeadNotifications = async (id) => {
  const results = [];
  const q = query(
    collection(db, "citasNegocios"),
    where("lead.id", "==", id),
    where("status", "==", "pending")
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });

  return results;
};

export const updateStatusAppointment = async (id, status) => {
  const appointmentRef = doc(db, "citasNegocios", id);

  await updateDoc(appointmentRef, {
    status,
  });
};

export const saveNegocio = async (id, data) => {
  const docsRef = doc(db, "negocios", id);
  await setDoc(docsRef, {
    nombreContacto: data?.nombreContacto,
    telefono: data?.telefono,
    email: data?.email,
    descripcion: data?.descripcion,
    empresa: data?.empresa,
    giro: data?.giro,
    createAt: new Date(),
  });
};

export const existsNegocioEmail = async (email) => {
  const q = query(collection(db, "negocios"), where("email", "==", email));

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.length > 0;
};
